import { FormElementTypes, IForm, IFormOptions, } from 'atlas-form';
import {
  DispositionAdditionalParams as DispositionAdditionalParamsBase, IDispositionFormOptionsProps,
} from './abstract/disposition.form.abstract';
import { IAuth, } from '@Store';
import { TFunction, } from 'i18next';
import { entitiesToOptions, } from '@Utils/options';
import { nestedArray, } from '@Utils/array';
import { filterRelated, } from '@Utils/relation';
import {
  CrudEnum, IPreviewDisposition,
  IPreviewOrganization,
  ISharedCreateDisposition,
  ISharedDisposition,
  PermissionEnum,
} from 'atlas-shared';

export const DispositionAdditionalParams: (auth: IAuth, props: IDispositionFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IDispositionFormOptionsProps) => ({
  ...DispositionAdditionalParamsBase(auth, props),
  disposition_id: { ui_type: FormElementTypes.TREE, label: 'PARENT', },
});

export const DispositionFormOptions = (disposition: ISharedCreateDisposition | ISharedDisposition, props: IDispositionFormOptionsProps, t: TFunction): IFormOptions => ({
  organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.Disposition, {})), 'organization_id', false), 'organization'),
  parents: (disposition2: ISharedDisposition) => filterRelated<IPreviewDisposition>(disposition2.organization_id || disposition.organization_id, 'disposition', 'disposition', props.dispositions, true, it => it.organization_id === (disposition.organization_id || disposition2.organization_id) && !disposition2.global && it.id !== (disposition as ISharedDisposition)?.id),
});

export * from './abstract/disposition.form.abstract';
