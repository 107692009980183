import { CustomFormItemProps, IFormComponent, } from 'atlas-form';
import { bitwiseIncludes, CrudEnum, PermissionEnum, TRequired, } from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import { Checkbox, } from 'antd';
import { CheckboxOptionType, } from 'antd/lib/checkbox/Group';

export const FormComponentCrud: IFormComponent = ({ onChange, value, data, isSuperadminProfile = false, }: TRequired<CustomFormItemProps> & {isSuperadminProfile?: boolean}) => {

  const [_, row, ] = data;

  const { t, } = useTranslation();

  const ReadOption = { value: CrudEnum.Read, label: t('READ'), disabled: true, };
  const DeleteOption = { value: CrudEnum.Delete, label: t('DELETE'), };
  const EditOption = { value: CrudEnum.Update, label: t('EDIT'), };
  const CreateOption = { value: CrudEnum.Insert, label: t('CREATE'), };
  let options: Array<CheckboxOptionType & {value: CrudEnum}> = [ReadOption, ];

  if (
    !(row.id === PermissionEnum.Invoice && !isSuperadminProfile)
    && ![
      PermissionEnum.Audit,
      PermissionEnum.VoiceRecording,
      PermissionEnum.VoiceVoicemailMessage,
      PermissionEnum.Release,
    ].includes(row.id)
  )
    options.push(EditOption);

  if (
    (row.id !== PermissionEnum.Organization || isSuperadminProfile)
    && ![
      PermissionEnum.Audit,
      PermissionEnum.Invoice,
      PermissionEnum.DestinationRestrictor,
      PermissionEnum.Gdpr,
      PermissionEnum.GdprAnonymize,
      PermissionEnum.VoiceRecording,
      PermissionEnum.VoiceRecordingTranscript,
      PermissionEnum.VoiceRecordingTranscriptSetting,
      PermissionEnum.VoiceVoicemailMessage,
      PermissionEnum.Release,
    ].includes(row.id)
  ) {
    options.push(DeleteOption);
    options.push(CreateOption);
  }
  else {
    if (bitwiseIncludes(value, CrudEnum.Insert) && !isSuperadminProfile)
      onChange(value - CrudEnum.Insert);

    if (bitwiseIncludes(value, CrudEnum.Delete))
      onChange(value - CrudEnum.Delete);
  }

  if (row.id === PermissionEnum.VoiceRoute) {
    if (!isSuperadminProfile) {
      options = options.filter(o => o.value !== CreateOption.value);
      if (bitwiseIncludes(value, CrudEnum.Insert) && !isSuperadminProfile)
        onChange(value - CrudEnum.Insert);
    }
  }

  if (row.id === PermissionEnum.PhonenumberStock) {
    options = options.filter(o => ![EditOption.value, ].includes(o.value));
    if (bitwiseIncludes(value, CrudEnum.Update))
      onChange(value - CrudEnum.Update);
  }

  if (row.id === PermissionEnum.PhonenumberPurchase) {
    options = [ReadOption, CreateOption, ];

    if (isSuperadminProfile)
      options.push(EditOption);

    if (bitwiseIncludes(value, CrudEnum.Update) && !isSuperadminProfile)
      onChange(value - CrudEnum.Update);

    if (bitwiseIncludes(value, CrudEnum.Delete))
      onChange(value - CrudEnum.Delete);
  }

  if (row.id === PermissionEnum.Release && value > CrudEnum.Read)
    onChange(CrudEnum.Read);

  if (row.conditions && bitwiseIncludes(value, CrudEnum.Insert)) {
    onChange(value - CrudEnum.Insert);
    return <></>;
  }

  if ((row.allow || isSuperadminProfile) && !bitwiseIncludes(value, CrudEnum.Read)) {
    onChange(value + CrudEnum.Read);
    return <></>;
  }
  else if (!row.allow && !isSuperadminProfile && bitwiseIncludes(value, CrudEnum.Read)) {
    onChange(value - CrudEnum.Read);
    return <></>;
  }

  if ([PermissionEnum.Organization, PermissionEnum.DestinationRestrictor, PermissionEnum.Gdpr, ].includes(row.id) && !isSuperadminProfile && value > 3)
    onChange(3);
  else if (row.id === PermissionEnum.Invoice && value > 3)
    onChange(3);

  return <div style={{ width: 270, }}>
    <Checkbox.Group options={options} defaultValue={options.filter(o => bitwiseIncludes(value, o.value as number)).map(o => o.value)} onChange={(selected: Array<any>) => {
      let sum = selected.reduce((a, b) => a + b, 0);

      if (row.allow && sum > CrudEnum.Read && !bitwiseIncludes(sum, CrudEnum.Read))
        sum += CrudEnum.Read;

      if (row.conditions && bitwiseIncludes(sum, CrudEnum.Insert))
        sum -= CrudEnum.Insert;

      onChange(sum);
    }} />
  </div>;
};
