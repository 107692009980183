import React, { useMemo, } from 'react';
import { TypeConversationMessage, ChannelEnum, ISharedMailMessage, ISharedVoiceCall, ISharedDraft, } from 'atlas-shared';
import './conversation.message.reply.scss';
import { ChannelsIcon, Icon, } from '@Components/misc';
import { useTranslation, } from 'react-i18next';
import { TranslateAndDisplayPhoneNumber, } from '@Utils';
import { ArrowCornerDownIcon, } from '@Assets';
import { THighlightedMessage, } from '@Hooks';

interface IProps {
  ownerMessage?: TypeConversationMessage;
  ownerDraft?: ISharedDraft;
  message: TypeConversationMessage;
  from?: true;
  highlightedMessage: THighlightedMessage;
}
export const ConversationMessageReply = React.memo(({ ownerMessage, ownerDraft, message, from, highlightedMessage, }: IProps) => {
  const { t, } = useTranslation();
  const title = useMemo(() => {
    switch (message.channel) {
    case ChannelEnum.Mail:
      return (message as ISharedMailMessage).subject;
    case ChannelEnum.Voice:
      return <TranslateAndDisplayPhoneNumber t={t} phone_number={(message as ISharedVoiceCall).contact_phone} />;
    default:
      return `${t(`CHANNEL_${message.channel}`)} #${message.id}`;
    }
  }, []);
  const setHighlightedMessage = highlightedMessage[1];
  const setClickedHighlightedMessage = highlightedMessage[3];
  const description = useMemo(() => {

    if (ownerDraft)
      return 'REPLY_TO';

    else if (ownerMessage?.channel === ChannelEnum.InternalNote)
      return from ? 'HAS_NOTE' : 'NOTE_TO';

    else if (message.channel === ChannelEnum.Voice)
      return from ? 'CONTAINS_TRANSFER_FROM' : 'TRANSFERRED_TO';

    else if (message.channel === ChannelEnum.InternalNote)
      return from ? 'HAS_NOTE' : 'NOTE_TO';

    return from ? 'REPLY_TO' : 'REPLIED_BY';
  }, []);

  return <div 
    className='conversation-message-replies-reply'
    onClick={() => setClickedHighlightedMessage({ channel: message.channel, id: message.id, })}
    onMouseOver={() => setHighlightedMessage({ channel: message.channel, id: message.id, })}
    onMouseOut={() => setHighlightedMessage(undefined)}
  >
    <div className='arrow'>
      <Icon icon={ArrowCornerDownIcon} iconProps={{ size: 17, style: { color: 'var(--blue-grey)', }, }} />
    </div>
    <div className='message'>
      <div className='title'>
        <div className='channel-icon'><ChannelsIcon channels={[{ c: message.channel, d: (message as any)?.direction, }, ]} size={12} padding={3} /></div>
        <span className='text'>{t(description)}</span>
      </div>
      <div className='message-title'>{title}</div>
      <div className='view-details'>{t('VIEW_DETAILS')}</div>
    </div>
  </div>;
}, () => true);
