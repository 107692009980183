import React, { useCallback, useEffect, useMemo, useState, } from 'react';

import { useTranslation, } from 'react-i18next';
import { useNavigate, } from 'react-router-dom';
import {
  FormAddForm,
  FormAddInitialValues,
  FormComponents,
  FormAdditionalParams,
  FormFormOptions,
  onFormFormSaved,
  IFormFormOptionsProps,
  AlertError,
  queryParam,
} from '@Utils';
import { AtlasForm, IFormOptions, } from 'atlas-form';
import { CrudEnum, ISharedCreateForm, PermissionEnum, } from 'atlas-shared';
import { createForm, fetchForm, getFormCreateSchema, } from '@Api';
import { RuleProtected, } from '@Containers';
import { withAdd, IWithAddProps, } from '@Hocs/with.add';
import { useAbilities, useAuth, useOrganizations, useFields, } from '@Hooks';
import { AppSpinner, } from '@Components';
import { useAppDispatch, } from '@Store';

interface IProps extends IWithAddProps<ISharedCreateForm> {}

const FormFormAdd = ({ values, setValues, save, formSchema, schemaLoading, saving, initialValues, title, onSaved, onCancel, onChange, hasAdvanced = false, }: IProps) => {
  const organizations = useOrganizations();
  const fields = useFields();
  const abilities = useAbilities();
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useAppDispatch();

  const [cloned, setCloned, ] = useState<Partial<ISharedCreateForm>>();

  const param_props: IFormFormOptionsProps = {
    t,
    is_new: true,
    abilities,
    organizations: organizations.organizations,
    fields: fields.fields,
  };
  const formLayout = useMemo(() => FormAddForm(values, param_props, t), [values, organizations, fields, t, abilities, ]);
  const options: IFormOptions = useMemo(() => FormFormOptions(values || FormAddInitialValues, param_props, t), [values, organizations, fields, t, abilities, ]);
  
  const onValuesChange = useCallback((changedValue: any, values: ISharedCreateForm, form) => {
    setValues(values);
    onChange?.(values, changedValue, form);
  }, [setValues, ]);
  const onFinish = useCallback((values: ISharedCreateForm) => save(() => createForm(values).then(
    res => onSaved ? onSaved(res, navigate) : onFormFormSaved(t, 'add', res, navigate),
    err => {
      AlertError(t, {
        content: err,
      });
    }
  )), [save, navigate, t, ]);
  
  useEffect(() => {
    const id = queryParam('clone');

    if (id)
      fetchForm(+id)
        .then(({
          organization_id,
          type,
          title,
          display_title,
          __fields,
          __submission_display_fields,
          description,
        }) => {
          setCloned({
            organization_id,
            type,
            title,
            display_title,
            __fields,
            __submission_display_fields,
            description,
          });
        });
    else
      setCloned({});
  }, []);
  
  if (schemaLoading || !cloned)
    return <AppSpinner />;

  if (!formSchema)
    return <>{t('SCHEMA_NOT_FOUND')}</>;
  
  if (!organizations.loaded || !fields.loaded)
    return <>{t('LOADING_DEPENDENCIES')}</>;

  return (
    <RuleProtected
      isAllowed={() => !values || abilities.can(values.organization_id, CrudEnum.Insert, PermissionEnum.Form, values)}
    >
      <AtlasForm
        dispatch={dispatch}
        formLayout={formLayout}
        form={formSchema}
        options={options}
        components={FormComponents}
        additionalParams={FormAdditionalParams(auth, param_props)}
        onFinish={onFinish}
        onCancel={onCancel}
        onValuesChange={onValuesChange}
        initialValues={{ ...(cloned || {}), ...FormAddInitialValues, ...initialValues, }}
        saving={saving}
        title={title ? t(title) : `${t('ADD')} ${t('FORM')}`}
        hasAdvanced={hasAdvanced}
      />
    </RuleProtected>
  );
};

export const FormFormAddView = React.memo(withAdd(FormFormAdd, getFormCreateSchema, FormAddInitialValues));
