import React, { lazy, useEffect, useMemo, useState, } from 'react';

const EditorQuill = lazy(() => import('@Components/editor/editor'));

import { IFormElement, IFormElementHtml, } from '../atlas-form-core/Interfaces';
import { IPreviewCannedAnswer, ISharedOrganization, } from 'atlas-shared';
import { useTranslation, } from 'react-i18next';
import {
  TEditorAtOptions,
  TEditorHashOptions,
  useEditorAtOptions,
  useEditorHashAgentOptions, useEditorHashContactOptions,
  useEditorHashConversationOptions, useEditorHashFieldOptions,
} from '@Hooks';
import { IQuillFileUpload, } from '@Components/editor';
import { Icon, } from '@Components';
import { RefreshIcon, } from '@Assets';

const EDITOR_STATE_KEY = 'atlas-editor-state';

interface IProps {
  organization_id?: ISharedOrganization['id'];
  value?: string;
  onChange?: (e: string)=> void;
  onBlur?: (value: string)=> void;
  placeholder?: string;
  className?: string;
  atOptions?: IFormElementHtml['atOptions'];
  hashOptions?: IFormElementHtml['hashOptions'];
  canned_answers?: Array<IPreviewCannedAnswer>;
  style?: IFormElement['element_style'];
  file_upload?: IQuillFileUpload;
  disabled?: boolean;
}
function FormHtmlEditor (props: IProps) {
  const { onChange, value, canned_answers, organization_id, } = props;
  const { t, } = useTranslation();
  const atUserOptions = useEditorAtOptions();
  const hashContactOptions = useEditorHashContactOptions(t);
  const hashFieldOptions = useEditorHashFieldOptions(t, organization_id);
  const hashAgentOptions = useEditorHashAgentOptions(t);
  const hashConversationOptions = useEditorHashConversationOptions(t);
  const [atOptions, setAtOptions, ] = useState<TEditorAtOptions>();
  const [hashOptions, setHashOptions, ] = useState<TEditorHashOptions>();
  const [oldEditor, setOldEditor, ] = useState<boolean>(localStorage.getItem(EDITOR_STATE_KEY) === 'old');

  useEffect(() => {
    if (atUserOptions.length)
      setAtOptions((props.atOptions || []).map(key => ({
        user: atUserOptions,
      }[key] || [])).flat());
  }, [props.atOptions, atUserOptions, ]);

  useEffect(() => {
    setHashOptions((props.hashOptions || []).map(key => ({
      contact: hashContactOptions,
      agent: hashAgentOptions,
      conversation: hashConversationOptions,
      field: hashFieldOptions,
    }[key] || [])).flat());
  }, [props.hashOptions, hashContactOptions, hashAgentOptions, hashConversationOptions, hashFieldOptions, ]);

  const editorToggleButton = useMemo(() => {
    return <Icon
      icon={RefreshIcon}
      className='editor-toggle'
      onClick={() => {
        const state = !oldEditor;

        setOldEditor(state);
        localStorage.setItem(EDITOR_STATE_KEY, state ? 'old' : 'new');
      }}
      tooltip={{
        title: `Switch to ${oldEditor ? 'new' : 'old'} editor`,
      }}
    />;
  }, [oldEditor, ]);

  if (!atOptions || !hashOptions)
    return <></>;

  // if (!oldEditor)
  //   return <>
  //     <EditorMce
  //       placeholder={props.placeholder}
  //       className={props.className}
  //       atOptions={atOptions}
  //       hashOptions={hashOptions}
  //       onChangeTrigger={onChange}
  //       onBlur={v => props.onBlur?.(v)}
  //       defaultValue={value}
  //       canned_answers={canned_answers}
  //       style={props.style}
  //       file_upload={props.file_upload}
  //       disabled={props.disabled}
  //       editorToggle={editorToggleButton}
  //     />
  //   </>;

  return <>
    <EditorQuill
      placeholder={props.placeholder}
      className={props.className}
      atOptions={atOptions}
      hashOptions={hashOptions}
      onChangeTrigger={onChange}
      onBlur={v => props.onBlur?.(v)}
      defaultValue={value}
      canned_answers={canned_answers}
      style={props.style}
      file_upload={props.file_upload}
      disabled={props.disabled}
      editorToggle={editorToggleButton}
    />
  </>;
}

export default React.memo(FormHtmlEditor);
