import { IAuth, } from '@Store';
import {
  FormElementTypes,
  FormLayoutTypes,
  IForm, IFormAdditionalParams,
  IFormElement,
  IFormElementArray,
  IFormElementTypeahead,
  IFormOptions,
} from 'atlas-form';

import {
  IStatsWidgetFormOptionsProps,
  StatsWidgetAdditionalParams as StatsWidgetAdditionalParamsBase,
  StatsWidgetFormOptions as StatsWidgetFormOptionsBase,
} from './abstract/stats_widget.form.abstract';
import { TFunction, } from 'i18next';
import {
  AnsweredAbandonedWithinArray,
  EnumOptions,
  findMetricByAlias,
  findMetricByAliasOrId,
  GroupMetrics,
  IMetric,
  IPreviewDisposition,
  IPreviewOrganization,
  IPreviewQueue,
  IPreviewStatsCustomMetric,
  IPreviewStatsWidget,
  IPreviewStatsWidgetSettingsMetricProperty,
  IPreviewUser,
  ISharedCreateStatsWidget,
  ISharedStatsWidget,
  ISharedStatsWidgetSettingsMetricProperty,
  MessageAnsweredAbandonedWithinArray,
  MetricDataSourceEnum,
  MetricUnitTypeEnum,
  OrganizationDashboardReportingTypeEnum,
  StatsWidgetPeriodEnum,
  StatsWidgetTypeEnum,
  ThreadAnsweredAbandonedWithinArray,
  IPreviewPause, } from 'atlas-shared/dist';
import { filterRelated, } from '@Utils/relation';
import {
  getCustomMetricDefaultFormat,
  getCustomMetricSelectableFormats,
  getMetricDefaultFormat,
  getMetricSelectableFormats,
  getStatsWidgetMetricTitle,
  metric_count_options,
  metric_options,
  metricOptions,
  metricToOption,
  PAUSE_FILTER_METRICS,
  seconds_threshold_options,
  seconds_threshold_settings,
  stats_channels_options,
  stats_direction_options,
  stats_message_channels_options,
  stats_message_direction_options,
} from '@Utils/stats';
import { IOption, } from '@Utils/options';
import { FormComponentWidgetType, } from '@Components';

const metric_layout = (stats_custom_metrics: Array<IPreviewStatsCustomMetric>): IFormElementTypeahead => ({
  ui_layout: FormLayoutTypes.HORIZONTAL,
  ui_type: FormElementTypes.TYPEAHEAD,
  mode: 'multiple',
  onAdd: (selected: any, item: IFormElementTypeahead, value: Array<any>, stats_widget) => {
    return selected.map((s, i) => {
      const metric = findMetricByAliasOrId(s, stats_custom_metrics);

      const s_type = typeof s;
      const is_custom_metric = s_type === 'number';
      const found = ['string', 'number', ].includes(s_type) ? value?.find(v => v.value === s) : s;

      if (found)
        return found;

      const metric_props: ISharedStatsWidgetSettingsMetricProperty = {
        ...((item as IFormElementArray).map_items?.default || {}),
        format: (metric as IMetric)?.format || (is_custom_metric ? getCustomMetricDefaultFormat(s, stats_widget as ISharedStatsWidget, stats_custom_metrics) : getMetricDefaultFormat(s, stats_widget as ISharedStatsWidget)),
        value: (`${s}${Math.random()}`).replace('0.', '__'),
      } as ISharedStatsWidgetSettingsMetricProperty;

      if (is_custom_metric)
        metric_props.stats_custom_metric_id = s;
      else
        metric_props.alias = s;

      return metric_props;
    });
  },
  tagRender: (item: IPreviewStatsWidgetSettingsMetricProperty, options: Array<IOption>, t:TFunction) => {
    return getStatsWidgetMetricTitle(t, item, stats_custom_metrics).elm;
  },
  tag_style: {
    maxWidth: 160,
  },
});

const metric_layout_group: IFormElementTypeahead = {
  ...metric_layout([]),
  advanced: true,
  onAdd: (selected: any, item: IFormElementTypeahead, value: Array<any>, stats_widget) => {
    return selected.map((s, i) => {
      const found = typeof s === 'string' ? value?.find(v => v.value === s) : s;

      return found || {
        ...((item as IFormElementArray).map_items?.default || {}),
        alias: s,
        format: getMetricDefaultFormat(s, stats_widget as ISharedStatsWidget),
        value: s,
      };
    });
  },
};

const color_element_layout: IFormElement = {
  ui_type: FormElementTypes.COLOR,
};
const color_element_layout2: IFormElement = {
  ui_type: FormElementTypes.COLOR,
  labelWidth: '110px',
};

const hideLabel = {
  hideLabel: true,
};

export const StatsWidgetComponents: IForm['components'] = {
  type: FormComponentWidgetType,
};

export const StatsWidgetAdditionalParams: (auth: IAuth, props: IStatsWidgetFormOptionsProps) => IForm['additionalParams'] = (auth: IAuth, props: IStatsWidgetFormOptionsProps) => {
  const __metric_layout = metric_layout(props.stats_custom_metrics);
  const __metric_layout_group = { ...metric_layout_group, };
  const base = StatsWidgetAdditionalParamsBase(auth, props);
  const is_dashboard = (window.location.pathname as string).includes('dashboard');
  const isDisabled = (it: ISharedStatsWidget, item: ISharedStatsWidget) => {
    const organization = it.organization_id || item?.organization_id ? props.organizations.find(o => o.id === (it.organization_id || item.organization_id)) : null;

    return organization ? organization[is_dashboard ? 'reduced_dashboard_license_type' : 'reduced_reporting_license_type'] === OrganizationDashboardReportingTypeEnum.Entry : true;
  };
  const _metric_layout_with_disabled = (it: ISharedStatsWidget, extras: IFormAdditionalParams = {}, item) => ({
    ...__metric_layout,
    disabled: isDisabled(it, item),
    ...extras,
  });
  const _metric_layout_group_with_disabled = (it: ISharedStatsWidget, extras: IFormAdditionalParams = {}, item) => ({
    ...__metric_layout_group,
    disabled: isDisabled(it, item),
    ...extras,
  });

  return {
    ...base,
    type: (it: ISharedStatsWidget, b, c, d, item) => ({ hidden: !props.is_new, disabled: isDisabled(it, item), }),
    settings: {
      ...hideLabel,
      shouldUpdate: (pre, next) => pre.values.type !== next.values.type,
    },
    settings_period: (it: ISharedStatsWidget, b, c, d, item) => ({
      shouldUpdate: (pre, next) => pre.values.type !== next.values.type,
      disabled: isDisabled(it, item),
    }),
    settings_period_type: (it) => ({
      hideLabel: true,
      shouldUpdate: (pre, next) => pre.values.type !== next.values.type,
    }),
    settings_period_from: {
      labelWidth: '50px',
      ui_settings: {
        format: 'YYYY-MM-DD HH:00',
      },
    },
    settings_period_to: {
      labelWidth: '50px',
      ui_settings: {
        format: 'YYYY-MM-DD HH:00',
      },
    },
    settings_time_interval: (it: ISharedStatsWidget, b, c, d, item) => ({
      ui_layout: FormLayoutTypes.INLINE,
      advanced: !it.settings.time_interval,
      disabled: isDisabled(it, item),
    }),
    settings_time_interval_from: {
      ui_type: FormElementTypes.TIME,
    },
    settings_time_interval_to: {
      ui_type: FormElementTypes.TIME,
    },
    settings_filter: {
      hideLabel: true,
      legend: 'FILTER',
    },
    settings_summary: (it: ISharedStatsWidget) => ({ advanced: !it.settings.summary, hidden: ![StatsWidgetTypeEnum.Pie, StatsWidgetTypeEnum.Polar, ].includes(it.type) && ![...(it.settings.group_by || []), ...(it.settings.sub_group_by || []), ].length, }),
    settings_trend: (it, b, c, d, item) => ({ disabled: isDisabled(it, item), }),
    settings_trend_adjust: { labelWidth: '40px', },
    settings_trend_adjust_subtract: { labelWidth: '50px', },
    settings_trend_adjust_unit: { labelWidth: '50px', },
    settings_trend_text_indicator: { labelWidth: '90px', },
    settings_metrics: (it, b, c, d, item) => _metric_layout_with_disabled(it, {}, item),
    settings_nested_metrics: (it, b, c, d, item) => ({
      ui_layout: FormLayoutTypes.INLINE,
      disabled: isDisabled(it, item),
    }),
    settings_nested_metrics_metrics: (it, b, c, d, item) => _metric_layout_with_disabled(it, { hideLabel: true, }, item),
    settings_group_by: (it, b, c, d, item) => _metric_layout_group_with_disabled(it, { advanced: false, }, item),
    settings_sub_group_by: (it, b, c, d, item) => _metric_layout_group_with_disabled(it, { hideLabel: false, }, item),
    settings_split_by: (it, b, c, d, item) => _metric_layout_group_with_disabled(it, { hideLabel: false, }, item),
    settings_sort_by: (it, b, c, d, item) => ({ disabled: isDisabled(it, item), advanced: true, hidden: ![StatsWidgetTypeEnum.Table, StatsWidgetTypeEnum.Grid, ].includes(it.type), }),
    settings_filter_row_type: { labelWidth: '50px', },
    settings_filter_row_rules: hideLabel,
    settings_filter_row_rules_metric: hideLabel,
    settings_filter_row_rules_comparison: hideLabel,
    settings_filter_row_rules_value: hideLabel,
    settings_filter_row_rules_value_arr: {
      ...hideLabel,
      mode: 'tags',
    },
    settings_filter_main_channels: { advanced: true, hidden: true, },
    settings_filter_thread_channels: { advanced: true, hidden: true, },
    settings_filter_message_channels: { advanced: true, hidden: true, },
    settings_filter_conversation_user_ids: (it: ISharedStatsWidget) => ({ advanced: true, label: 'USERS', }),
    settings_filter_thread_user_ids: { advanced: true, },
    settings_filter_message_user_ids: { advanced: true, },
    settings_filter_conversation_queue_ids: { advanced: true, },
    settings_filter_thread_queue_ids: { advanced: true, },
    settings_filter_message_queue_ids: { advanced: true, },
    settings_filter_pause_ids: (it: ISharedStatsWidget) => ({ advanced: true, hidden: !it.settings.group_by?.some(m => m.alias === 'pause_id'), }),
    settings_filter_disposition_ids: { advanced: true, },
    settings_filter_sub_disposition_ids: { advanced: true, },
    settings_filter_thrd_disposition_ids: { advanced: true, },
    settings_filter_mail_account_ids: { advanced: true, },
    settings_filter_team_ids: { advanced: true, },
    settings_filter_thread_team_ids: { advanced: true, },
    settings_filter_message_team_ids: { advanced: true, },
    settings_filter_voice_route_ids: { advanced: true, },
    shared: { labelWidth: '80px', advanced: true, },
    shared_shared: { labelWidth: '40px', },
    shared_with: { labelWidth: '40px', },
    shared_with_users: { labelWidth: '80px', },
    shared_with_teams: { ...base?.shared_with_teams, labelWidth: '80px', },
    shared_with_agentprofiles: { labelWidth: '80px', },
    settings_group_by_color: color_element_layout,
    settings_group_by_bg_color: color_element_layout,
    settings_group_by_box_color: color_element_layout,
    settings_sub_group_by_color: color_element_layout,
    settings_sub_group_by_bg_color: color_element_layout,
    settings_sub_group_by_box_color: color_element_layout,
    settings_split_color: color_element_layout,
    settings_split_bg_color: color_element_layout,
    settings_split_box_color: color_element_layout,
    settings_metrics_user_self: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = settings.alias ? findMetricByAlias(settings.alias) : undefined;

      return { help: 'STATS_USER_DATA_HELP', hideHelp: true, hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent, };
    },
    settings_nested_metrics_metrics_user_self: { help: 'STATS_USER_DATA_HELP', hideHelp: true, },
    settings_metrics_of_total: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      return { label: 'OF_TOTAL', help: 'STATS_OF_TOTAL_HELP', hideHelp: true, hidden: !!settings.stats_custom_metric_id || !findMetricByAlias(settings.alias as string)?.of_total || (!settings.user_self && ![...(it.settings.group_by || []), ...(it.settings.sub_group_by || []), ].length), };
    },
    settings_metrics_custom_percent: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      return { label: 'CUSTOM_PERCENT', help: 'STATS_CUSTOM_PERCENT_HELP', hideHelp: true, hidden: !findMetricByAlias(settings.alias as string)?.custom_percent, };
    },
    settings_metrics_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('seconds_threshold', it, settings),
    settings_nested_metrics_metrics_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('seconds_threshold', it, settings),
    settings_metrics_thread_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('thread_seconds_threshold', it, settings),
    settings_nested_metrics_metrics_thread_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('thread_seconds_threshold', it, settings),
    settings_metrics_message_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('message_seconds_threshold', it, settings),
    settings_nested_metrics_metrics_message_seconds_threshold: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => seconds_threshold_settings('message_seconds_threshold', it, settings),
    settings_metrics_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { label: metric?.sources?.includes(MetricDataSourceEnum.UserActivity) ? 'CHANNELS' : 'CONVERSATION_MAIN_CHANNELS', labelWidth: '160px', hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.channels && metric.channels.length <= 1), };
    },
    settings_nested_metrics_metrics_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { label: metric?.sources?.includes(MetricDataSourceEnum.UserActivity) ? 'CHANNELS' : 'CONVERSATION_MAIN_CHANNELS', labelWidth: '160px', hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.channels && metric.channels.length <= 1), };
    },
    settings_metrics_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { label: 'CONVERSATION_DIRECTION', hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.directions && metric.directions?.length <= 1), };
    },
    settings_nested_metrics_metrics_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { label: 'CONVERSATION_DIRECTION', hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.directions && metric.directions?.length <= 1), };
    },
    settings_metrics_thread_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.thread_channels && metric.thread_channels.length <= 1), };
    },
    settings_nested_metrics_metrics_thread_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.thread_channels && metric.thread_channels.length <= 1), };
    },
    settings_metrics_message_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.message_channels && metric.message_channels.length <= 1), };
    },
    settings_nested_metrics_metrics_message_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.message_channels && metric.message_channels.length <= 1), };
    },
    settings_metrics_thread_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.thread_directions && metric.thread_directions.length <= 1), };
    },
    settings_metrics_message_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.message_directions && metric.message_directions.length <= 1), };
    },
    settings_nested_metrics_metrics_thread_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.thread_directions && metric.thread_directions.length <= 1), };
    },
    settings_nested_metrics_metrics_message_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent || (metric?.message_directions && metric.message_directions.length <= 1), };
    },
    settings_metrics_format: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: settings.of_total || metric?.unit_type === MetricUnitTypeEnum.TimelineEvent, };
    },
    settings_metrics_filter: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = findMetricByAliasOrId((settings.alias || settings.stats_custom_metric_id) as any, props.stats_custom_metrics) as IMetric | undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent, };
    },
    settings_metrics_color: color_element_layout,
    settings_nested_metrics_metrics_color: color_element_layout,
    settings_metrics_bg_color: color_element_layout,
    settings_nested_metrics_metrics_bg_color: color_element_layout,
    settings_nested_metrics_metrics_box_color: color_element_layout,
    settings_metrics_box_color: color_element_layout,
    settings_metrics_border: { ui_layout: FormLayoutTypes.INLINE, },
    settings_nested_metrics_metrics_border: { ui_layout: FormLayoutTypes.INLINE, },
    settings_group_by_border: { ui_layout: FormLayoutTypes.INLINE, },
    settings_metrics_border_color: color_element_layout,
    settings_nested_metrics_metrics_border_color: color_element_layout,
    settings_group_by_border_color: color_element_layout,
    settings_metrics_thresholds: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      const metric = settings.alias ? findMetricByAlias(settings.alias) : undefined;

      return { hidden: metric?.unit_type === MetricUnitTypeEnum.TimelineEvent, };
    },
    settings_metrics_thresholds_metric: hideLabel,
    settings_metrics_thresholds_comparison: hideLabel,
    settings_metrics_thresholds_value: hideLabel,
    settings_metrics_thresholds_value_arr: { ...hideLabel, mode: 'tags', },
    settings_metrics_thresholds_color: color_element_layout,
    settings_metrics_thresholds_bg_color: color_element_layout,
    settings_metrics_thresholds_box_color: color_element_layout,
    settings_metrics_thresholds_border_color: color_element_layout,
    settings_metrics_filter_pause_ids: (it: ISharedStatsWidget, _, __, settings: ISharedStatsWidgetSettingsMetricProperty) => ({ hidden: !settings.alias || !PAUSE_FILTER_METRICS.includes(settings.alias), }),
    settings_nested_metrics_metrics_thresholds_metric: hideLabel,
    settings_nested_metrics_metrics_thresholds_comparison: hideLabel,
    settings_nested_metrics_metrics_thresholds_value: hideLabel,
    settings_nested_metrics_metrics_thresholds_value_arr: { ...hideLabel, mode: 'tags', },
    settings_nested_metrics_metrics_thresholds_color: color_element_layout,
    settings_nested_metrics_metrics_thresholds_bg_color: color_element_layout,
    settings_nested_metrics_metrics_thresholds_box_color: color_element_layout,
    settings_nested_metrics_metrics_thresholds_border_color: color_element_layout,
    settings_nested_metrics_metrics_filter_pause_ids: (it: ISharedStatsWidget, _, __, settings: ISharedStatsWidgetSettingsMetricProperty) => ({ hidden: !settings.alias || !PAUSE_FILTER_METRICS.includes(settings.alias), }),
    settings_split_by_thresholds: { hidden: true, },
    settings_group_by_thresholds: { hidden: true, },
    settings_sub_group_by_thresholds: { hidden: true, },
    settings_ui: { advanced: true, },
    settings_ui_border: { labelWidth: '80px', },
    settings_ui_border_with: { labelWidth: '40px', },
    settings_ui_border_color: { labelWidth: '40px', ...color_element_layout, },
    settings_ui_table: { hideLabel: true, },
    settings_ui_table_group: { labelWidth: '80px', },
    settings_ui_table_sub_group: { labelWidth: '80px', },
    settings_ui_table_group_odd_row_color: color_element_layout2,
    settings_ui_table_group_even_row_color: color_element_layout2,
    settings_ui_table_sub_group_odd_row_color: color_element_layout2,
    settings_ui_table_sub_group_even_row_color: color_element_layout2,
    settings_ui_table_group_odd_row_bg_color: color_element_layout2,
    settings_ui_table_group_even_row_bg_color: color_element_layout2,
    settings_ui_table_sub_group_odd_row_bg_color: color_element_layout2,
    settings_ui_table_sub_group_even_row_bg_color: color_element_layout2,
    settings_ui_legend: { labelWidth: '80px', },
    settings_ui_legend_placement: { labelWidth: '60px', },
    settings_ui_display_values: { labelWidth: '85px', },
    settings_ui_display_header: { labelWidth: '85px', },
    settings_ui_cutout: { postFixText: '%', labelWidth: '80px', },
  };
};

const group_metrics_options = (t: TFunction, stats_widget: ISharedCreateStatsWidget) => (stats_widget?.settings?.period?.type === StatsWidgetPeriodEnum.LIVE_TODAY ? GroupMetrics : GroupMetrics.filter(m => !m.live)).map(m => metricToOption(m, t));
const metric_format_options = (t: TFunction, stats_custom_metrics: Array<IPreviewStatsCustomMetric>) => {
  return (a: IPreviewStatsWidget, metric: IPreviewStatsWidgetSettingsMetricProperty) => {
    return (metric.stats_custom_metric_id ? getCustomMetricSelectableFormats(metric.stats_custom_metric_id, stats_custom_metrics) : getMetricSelectableFormats(metric.alias as string)).map(format => ({
      key: format,
      title: t(`METRIC_FORMAT_${format}`),
    }));
  };
};

export const StatsWidgetFormOptions = (stats_widget: ISharedCreateStatsWidget | ISharedStatsWidget, props: IStatsWidgetFormOptionsProps, t: TFunction): IFormOptions => {
  const _metric_count_options = metric_count_options(t, props.stats_custom_metrics);
  const _metric_format_options = metric_format_options(t, props.stats_custom_metrics);
  // TODO: implement custom metrics in nested metrics
  const _settings_nested_metrics_metrics = (t: TFunction) => {
    return (a: IPreviewStatsWidget) => {
      const first_metric = a.settings.nested_metrics?.flatMap(nm => nm.metrics)?.[0];
      const unit_type = first_metric && findMetricByAliasOrId((first_metric.alias || first_metric.stats_custom_metric_id) as any, props.stats_custom_metrics)?.unit_type;

      if (unit_type)
        return metricOptions(t, props.stats_custom_metrics, stats_widget, unit_type);

      return _metric_count_options;
    };
  };
  const is_dashboard = (window.location.pathname as string).includes('dashboard');
  const isEntry = (it: ISharedStatsWidget, key: 'reduced_reporting_license_type' | 'reduced_dashboard_license_type') => {
    const organization = props.organizations.find(o => o.id === (it.organization_id || stats_widget.organization_id));

    if (organization)
      return organization[key] === OrganizationDashboardReportingTypeEnum.Entry;

    return true;
  };

  const queue_options = (it: ISharedStatsWidget) => [{ key: 0, title: t('NO_QUEUE'), }, ...filterRelated<IPreviewQueue>(it.organization_id || stats_widget.organization_id, 'queue', 'stats_widget', props.queues, true), ];
  const user_options = (it: ISharedStatsWidget) => [{ key: 0, title: t('NO_USER'), }, ...filterRelated<IPreviewUser>(it.organization_id || stats_widget.organization_id, 'user_reverse', 'stats_widget', props.users, true), ];
  const disposition_options = (txt: string) => (it: ISharedStatsWidget) => ([{ key: 0, title: t(txt), }, ...filterRelated<IPreviewDisposition>(it.organization_id || stats_widget.organization_id, 'disposition', 'stats_widget', props.dispositions, true), ]);
  const sub_disposition_options = (txt: string) => (it: ISharedStatsWidget) => ([{ key: 0, title: t(txt), }, ...filterRelated<IPreviewDisposition>(it.organization_id || stats_widget.organization_id, 'disposition', 'stats_widget', props.dispositions, true, disposition => !!(disposition.parents || []).length), ]);
  const pause_options = (it: ISharedStatsWidget) => [
    { key: 0, title: t('NO_PAUSE'), },
    { key: -1, title: t('ACW'), },
    ...filterRelated<IPreviewPause>(it.organization_id || stats_widget.organization_id, 'pause', 'stats_widget', props.pauses, true),
  ];

  return {
    ...StatsWidgetFormOptionsBase(stats_widget, props, t),
    //organization_id: entitiesToOptions<IPreviewOrganization>(nestedArray<any>(props.organizations.filter(organization => organization[is_dashboard ? 'reduced_dashboard_license_type' : 'reduced_reporting_license_type'] !== OrganizationDashboardReportingTypeEnum.Entry && props.abilities.can(organization.id, CrudEnum.Insert, PermissionEnum.StatsWidget, {})), 'organization_id', false), 'organization'),
    settings_filter_organization_ids: (it: ISharedStatsWidget) => filterRelated<IPreviewOrganization>(it.organization_id || stats_widget.organization_id, 'organization', 'stats_widget', props.organizations, true),
    settings_filter_disposition_ids: disposition_options('NO_DISPOSITION'),
    settings_filter_sub_disposition_ids: disposition_options('NO_SUB_DISPOSITION'),
    settings_filter_thrd_disposition_ids: sub_disposition_options('NO_THRD_DISPOSITION'),

    settings_filter_conversation_queue_ids: queue_options,
    settings_filter_thread_queue_ids: queue_options,
    settings_filter_message_queue_ids: queue_options,
    settings_filter_pause_ids: pause_options,
    settings_metrics_filter_conversation_queue_ids: queue_options,
    settings_metrics_filter_thread_queue_ids: queue_options,
    settings_metrics_filter_message_queue_ids: queue_options,
    settings_metrics_filter_pause_ids: pause_options,
    settings_nested_metrics_metrics_filter_conversation_queue_ids: queue_options,
    settings_nested_metrics_metrics_filter_thread_queue_ids: queue_options,
    settings_nested_metrics_metrics_filter_message_queue_ids: queue_options,
    settings_nested_metrics_metrics_filter_pause_ids: pause_options,

    settings_filter_conversation_user_ids: user_options,
    settings_filter_thread_user_ids: user_options,
    settings_filter_message_user_ids: user_options,
    settings_metrics_filter_conversation_user_ids: user_options,
    settings_metrics_filter_thread_user_ids: user_options,
    settings_metrics_filter_message_user_ids: user_options,
    settings_nested_metrics_metrics_filter_conversation_user_ids: user_options,
    settings_nested_metrics_metrics_filter_thread_user_ids: user_options,
    settings_nested_metrics_metrics_filter_message_user_ids: user_options,

    settings_period_type: (it: ISharedStatsWidget) => {
      let options = EnumOptions('stats_widget_period', StatsWidgetPeriodEnum, t);

      if (!is_dashboard) {
        options = options.filter(o => o.key !== StatsWidgetPeriodEnum.LIVE_TODAY);
        if (isEntry(it, 'reduced_reporting_license_type'))
          options = options.map(o => o.key === StatsWidgetPeriodEnum.CUSTOM ? { ...o, disabled: true, } : o);
      }
      else if (isEntry(it, 'reduced_reporting_license_type'))
        options = options.filter(o => [StatsWidgetPeriodEnum.LIVE_TODAY, StatsWidgetPeriodEnum.TODAY, ].includes(o.key as any));

      if (it.type === StatsWidgetTypeEnum.Timeline)
        options = options.filter(o => [StatsWidgetPeriodEnum.TODAY, StatsWidgetPeriodEnum.YESTERDAY, StatsWidgetPeriodEnum.LAST_24_HOURS, StatsWidgetPeriodEnum.CUSTOM, ].includes(o.key as StatsWidgetPeriodEnum));

      return options;
    },
    settings_group_by: (it: ISharedStatsWidget) => group_metrics_options(t, it).filter(o => ![...(it.settings?.sub_group_by || []), ...(it.settings?.split_by || []), ].some(g => g.alias === o.key)),
    settings_sub_group_by: (it: ISharedStatsWidget) => group_metrics_options(t, it).filter(o => ![...(it.settings?.group_by || []), ...(it.settings?.split_by || []), ].some(g => g.alias === o.key)),
    settings_split_by: (it: ISharedStatsWidget) => group_metrics_options(t, it).filter(o => ![...(it.settings?.group_by || []), ...(it.settings?.sub_group_by || []), ].some(g => g.alias === o.key)),
    settings_metrics: (it: ISharedStatsWidget) => metric_options(t, props.stats_custom_metrics, it),
    settings_metrics_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.channels?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.channels?.includes(o.key as any));
    },
    settings_metrics_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.directions ? stats_direction_options(t) : stats_direction_options(t).filter(o => metric.directions?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.directions ? stats_direction_options(t) : stats_direction_options(t).filter(o => metric.directions?.includes(o.key as any));
    },
    settings_metrics_thread_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.thread_channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.thread_channels?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_thread_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.thread_channels ? stats_channels_options(t) : stats_channels_options(t).filter(o => metric.thread_channels?.includes(o.key as any));
    },
    settings_metrics_message_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_channels ? stats_message_channels_options(t) : stats_message_channels_options(t).filter(o => metric.message_channels?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_message_channels: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_channels_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_channels ? stats_message_channels_options(t) : stats_message_channels_options(t).filter(o => metric.message_channels?.includes(o.key as any));
    },
    settings_metrics_thread_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.thread_directions ? stats_message_direction_options(t) : stats_direction_options(t).filter(o => metric.thread_directions?.includes(o.key as any));
    },
    settings_metrics_message_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_directions ? stats_message_direction_options(t) : stats_message_direction_options(t).filter(o => metric.message_directions?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_thread_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_directions ? stats_message_direction_options(t) : stats_direction_options(t).filter(o => metric.message_directions?.includes(o.key as any));
    },
    settings_nested_metrics_metrics_message_direction: (it: ISharedStatsWidget, settings: ISharedStatsWidgetSettingsMetricProperty) => {
      if (!settings.alias) {
        if (settings.stats_custom_metric_id)
          return stats_message_direction_options(t);

        return [];
      }

      const metric = findMetricByAlias(settings.alias);

      if (!metric)
        return [];

      return !metric.message_directions ? stats_message_direction_options(t) : stats_message_direction_options(t).filter(o => metric.message_directions?.includes(o.key as any));
    },
    settings_metrics_seconds_threshold: seconds_threshold_options(t, AnsweredAbandonedWithinArray),
    settings_nested_metrics_metrics_seconds_threshold: seconds_threshold_options(t, AnsweredAbandonedWithinArray),
    settings_metrics_thread_seconds_threshold: seconds_threshold_options(t, ThreadAnsweredAbandonedWithinArray),
    settings_nested_metrics_metrics_thread_seconds_threshold: seconds_threshold_options(t, ThreadAnsweredAbandonedWithinArray),
    settings_metrics_message_seconds_threshold: seconds_threshold_options(t, MessageAnsweredAbandonedWithinArray),
    settings_nested_metrics_metrics_message_seconds_threshold: seconds_threshold_options(t, MessageAnsweredAbandonedWithinArray),
    settings_group_by_format: _metric_format_options,
    settings_sub_group_by_format: _metric_format_options,
    settings_split_by_format: _metric_format_options,
    settings_metrics_format: _metric_format_options,
    settings_nested_metrics_metrics_format: _metric_format_options,
    settings_nested_metrics_metrics: _settings_nested_metrics_metrics(t),
    settings_filter_row_rules_metric: _metric_count_options,
    settings_filter_main_channels: stats_channels_options(t),
    settings_filter_thread_channels: stats_channels_options(t),
    settings_filter_message_channels: stats_message_channels_options(t),
    settings_sort_by_metric: (it: ISharedStatsWidget) => ([
      ...[
        ...(it.settings.group_by || []),
        ...(it.settings.sub_group_by || []),
        ...(it.settings.split_by || []),
      ].map(m => ({
        key: m.value,
        title: m.title || findMetricByAliasOrId((m.alias || (m as ISharedStatsWidgetSettingsMetricProperty).stats_custom_metric_id) as any, props.stats_custom_metrics)?.title || m.alias || 'Title not found!',
      })),
      ...[
        ...(it.settings.metrics || []),
        ...(it.settings.nested_metrics || []).flatMap(nm => nm.metrics),
      ].map(m => ({
        key: m.value,
        title: m.title || findMetricByAliasOrId((m.alias || m.stats_custom_metric_id) as any, props.stats_custom_metrics)?.title || m.alias || 'Title not found!',
      })),
    ]),
  };
};

export * from './abstract/stats_widget.form.abstract';
