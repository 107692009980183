import React, { useCallback, useState, } from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useAuth, useAbilities, useIsPrint, useIsFullScreen, useDisplayChangelog, } from '@Hooks';
import {
  Header,
  AdminMenu,
  Notifications,
  Tabs,
  VoiceCallModal,
  VoiceCallsModal,
  StatsWrapper,
} from '@Components';
import { HandlingFocusedConversationHandler, } from '@Containers';
import { Changelog, } from '@Components/dashboard/changelog/changelog';
import { DocumentViewer, } from './document.viewer';

export const AppLayout = React.memo(({ children, }: React.PropsWithChildren<{}>) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const abilities = useAbilities();
  const location = useLocation();
  const isPrint = useIsPrint();
  const isFullscreen = useIsFullScreen();
  const displayChangelog = useDisplayChangelog();

  const [menuVisible, setMenuVisible, ] = useState(location.pathname.startsWith('/admin'));
  const toggleMenu = useCallback(() => setMenuVisible(!menuVisible), [setMenuVisible, menuVisible, ]);

  if (!auth) {
    navigate('/');
    return <></>;
  }

  if (!abilities)
    return <></>;

  return (
    <HandlingFocusedConversationHandler auth={auth}>
      <DocumentViewer />
      {!isPrint && !isFullscreen && <Header onToggleMenu={toggleMenu}/>}
      <VoiceCallModal />
      <VoiceCallsModal />
      {auth.user.main && <Notifications />}
      <div className={'content-master-wrp'}>
        {displayChangelog && <Changelog auth={auth} />}
        {!isPrint && !isFullscreen && <AdminMenu visible={menuVisible} setVisible={setMenuVisible} abilities={abilities}/>}
        <div className={'content-children-wrp'}>
          {children}
        </div>
        {auth.user.dashboard_license && <StatsWrapper/>}
      </div>
      {auth.user.main && location.pathname.startsWith('/dashboard') && <Tabs />}
    </HandlingFocusedConversationHandler>
  );
});
