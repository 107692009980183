import React, { useEffect, useMemo, useState, } from 'react';
import './conversation.message.scss';
import {
  ISharedFullConversation,
  ISharedInternalNote,
  ISharedMailMessage,
  TypeConversationMessage,
  ChannelEnum,
  IPreviewUser,
  TConversationVoiceCall,
  ISharedFormSubmission,
  IPreviewTab,
} from 'atlas-shared';
import { ConversationMessageInternalNote, } from './conversation.message.internal_note';
import { ConversationMessageVoiceCall, } from './conversation.message.voice_call';
import { ConversationMessageFormSubmission, } from './conversation.message.form_submission';
import { ConversationMessageMail, } from './conversation.message.mail';
import { IAuth, } from '@Store';
import { TFunction, } from 'i18next';
import { THighlightedMessage, useUsers, } from '@Hooks';
import { ConversationMessageAvatar, } from './conversation.message.avatar';

interface IProps {
  dispatch: any;
  t: TFunction;
  auth: IAuth;
  index: number;
  message: TypeConversationMessage;
  conversation: ISharedFullConversation;
  highlightedMessage: THighlightedMessage;
  tab?: IPreviewTab;
}

export const ConversationMessage = React.memo(({ dispatch, t, auth, conversation, message, highlightedMessage, tab, index, }: IProps) => {

  const [user, setUser, ] = useState<IPreviewUser>();
  const users = useUsers();

  useEffect(() => {
    if ((message as any).user_id)
      setUser(users.dict[(message as any).user_id]);
  }, []);

  const avatar = useMemo(() => <ConversationMessageAvatar user={user} />, [user, ]);

  return (
    <span id={`conversation-message-${highlightedMessage[4]}-${message.channel}-${message.id}`}>
      {
        message?.channel === ChannelEnum.InternalNote
        && <ConversationMessageInternalNote
          conversation={conversation}
          message={message as ISharedInternalNote}
          t={t}
          auth={auth}
          avatar={avatar}
          highlightedMessage={highlightedMessage}
          tab={tab}
        />
      }
      {
        message?.channel === ChannelEnum.Mail
        && <ConversationMessageMail
          dispatch={dispatch}
          index={index}
          conversation={conversation}
          message={message as ISharedMailMessage}
          t={t}
          auth={auth}
          avatar={avatar}
          highlightedMessage={highlightedMessage}
          tab={tab}
        />
      }
      {
        message?.channel === ChannelEnum.Form
        && <ConversationMessageFormSubmission
          dispatch={dispatch}
          conversation={conversation}
          message={message as ISharedFormSubmission}
          t={t}
          auth={auth}
          highlightedMessage={highlightedMessage}
        />}
      {
        message?.channel === ChannelEnum.Voice
        && <ConversationMessageVoiceCall
          conversation={conversation}
          message={message as TConversationVoiceCall}
          auth={auth}
          highlightedMessage={highlightedMessage}
        />}
    </span>
  );
});
