import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useState, } from 'react';
import {
  ConversationStatusEnum,
  ConversationWatcherSourceEnum,
  IPreviewForm, IPreviewList,
  IPreviewQueue,
  ISharedConversation,
  ISharedFullConversation,
  ISharedFullConversationReducer,
  ISharedPatchConversation,
  Undefinable,
  Nullable,
  IPreviewContactCustomEvent,
  IPreviewConversation,
  ISharedContactCustomEvent,
  IConversationTimeline,
} from 'atlas-shared';
import {
  useAuth,
  useForms,
  useLists,
  useQueues,
  useFullConversation,
  useConstant,
  useFocusedConversation,
  useAbilities,
} from '@Hooks';
import { AlertError, } from '@Utils';
import {
  fetchContactCustomEvent,
  fetchContactTimeline,
  fetchConversationJourney,
  fetchConversationTimeline,
  patchConversation,
  readConversation,
} from '@Api';
import { useTranslation, } from 'react-i18next';
import { fromJson, } from 'json-joi-converter';
import {
  actionSetConversationJourney,
  actionSetHideDashboardLeft,
  actionSetHideStats,
  currentDashboardConversation,
  useAppDispatch,
} from '@Store';

import { AppSpinner, ConversationContact, ConversationForm, ConversationTitleBox, ConversationContactForm, CustomerJourneyList, } from '@Components';
import { useParams, } from 'react-router-dom';

interface IProps {
  organization_id: ISharedConversation['organization_id'];
  conversation_id: ISharedConversation['id'];
  mock?: true;
}

export interface IWithFullConversationProps extends IProps {
  conversation: ISharedFullConversationReducer;
  customerJourneyVisible: boolean;
  setCustomerJourneyVisible: Dispatch<SetStateAction<boolean>>;
  editContactVisible: boolean;
  setEditContactVisible: Dispatch<SetStateAction<boolean>>;
  editConversationVisible: boolean;
  setEditConversationVisible: Dispatch<SetStateAction<boolean>>;
  secondaryConversation: ISharedFullConversationReducer;
  secondaryConversationId: Undefinable<IPreviewConversation['id']>;
  setSecondaryConversationId: Dispatch<Undefinable<IPreviewConversation['id']>>;
  event: ISharedContactCustomEvent;
  eventId: Undefinable<IPreviewContactCustomEvent['id']>;
  setEventId: Dispatch<Undefinable<IPreviewContactCustomEvent['id']>>;
  conversationForm: Undefinable<IPreviewForm>;
  contact_view: React.ReactElement;
  conversation_title_view: React.ReactElement;
  customer_journey_view: React.ReactElement;
  contact_form_view: React.ReactElement;
  conversation_form_view: React.ReactElement | false;
  patch_conversation: (body: ISharedPatchConversation) => Promise<ISharedFullConversation>;
  conversationTimeline?: Array<IConversationTimeline>;
  onConversationTimelineClose: () => void;
  contactTimeline?: Array<IConversationTimeline>;
  onContactTimelineClose: () => void;
}

export function withFullConversation <T>(Component: React.JSXElementConstructor<any>, source: ConversationWatcherSourceEnum, mini: boolean = false, skeleton: ReactElement, skipWatcher: boolean = false) {

  return function (props: T & IProps) {

    const { secondary_conversation_id, contact_custom_event_id, } = useParams<'secondary_conversation_id' | 'contact_custom_event_id'>();
    const { t, } = useTranslation();
    const auth = useAuth();
    const abilities = useAbilities();
    const { organization_id, conversation_id, mock, } = props;
    const isSourceTab = useConstant(() => source === ConversationWatcherSourceEnum.Tab);
    const [customerJourneyVisible, setCustomerJourneyVisible, ] = useState(false);
    const [editContactVisible, setEditContactVisible, ] = useState(false);
    const [editConversationVisible, setEditConversationVisible, ] = useState(false);
    const [secondaryConversationId, setSecondaryConversationId, ] = useState<Undefinable<number>>();
    const [eventId, setEventId, ] = useState<Undefinable<number>>();
    const [list, setList, ] = useState<IPreviewList>();
    const conversation = useFullConversation(organization_id, conversation_id, source, auth, mock, skipWatcher);
    const secondaryConversation = useFullConversation(organization_id, secondaryConversationId, source === ConversationWatcherSourceEnum.Tab ? ConversationWatcherSourceEnum.TabCustomerJourney : ConversationWatcherSourceEnum.DashboardCustomerJourney, auth, mock);
    const [event, setEvent, ] = useState<Undefinable<ISharedContactCustomEvent>>();

    const [queue, setQueue, ] = useState<IPreviewQueue>();
    const [journeyLoaded, setJourneyLoaded, ] = useState<Nullable<boolean>>(null);
    const [conversationForm, setConversationForm, ] = useState<IPreviewForm>();
    const [contactForm, setContactForm, ] = useState<IPreviewForm>();
    const [contactInitialValues, setContactInitialValues, ] = useState<Record<string, any>>();
    const [conversationInitialValues, setConversationInitialValues, ] = useState<Record<string, any>>();
    const [conversationFormView, setConversationFormView, ] = useState<IWithFullConversationProps['conversation_form_view']>();
    const [conversationTimelineActive, setConversationTimelineActive, ] = useState<boolean>(false);
    const [contactTimelineActive, setContactTimelineActive, ] = useState<boolean>(false);
    const [conversationTimeline, setConversationTimeline, ] = useState<Undefinable<Array<IConversationTimeline>>>();
    const [contactTimeline, setContactTimeline, ] = useState<Undefinable<Array<IConversationTimeline>>>();
    const queues = useQueues();
    const forms = useForms();
    const lists = useLists();
    const focused_conversation = useFocusedConversation();
    const dispatch = useAppDispatch();

    const onConversationTimelineClose = useCallback(() => {
      setConversationTimeline(undefined);
      setConversationTimelineActive(false);
    }, [conversationTimeline, setConversationTimeline, ]);

    useEffect(() => {
      if (!conversationTimelineActive && conversationTimeline)
        setConversationTimeline(undefined);
    }, [conversationTimelineActive, ]);

    const onContactTimelineClose = useCallback(() => {
      setContactTimeline(undefined);
      setContactTimelineActive(false);
    }, [contactTimeline, setContactTimeline, ]);

    useEffect(() => {
      if (!contactTimelineActive && contactTimeline)
        setContactTimeline(undefined);
    }, [contactTimelineActive, ]);

    useEffect(() => {
      if (conversationTimelineActive && !conversationTimeline && conversation)
        fetchConversationTimeline(conversation.organization_id, conversation.id)
          .then(({ events, }) => setConversationTimeline(events))
        ;
    }, [conversationTimelineActive, ]);

    useEffect(() => {
      if (contactTimelineActive && !contactTimeline && conversation?.contact?.id)
        fetchContactTimeline(conversation.organization_id, conversation.contact.id)
          .then(({ events, }) => setContactTimeline(events))
        ;
    }, [contactTimelineActive, ]);

    useEffect(() => {
      onConversationTimelineClose();
      onContactTimelineClose();
    }, [conversation?.id, ]);

    useEffect(() => {
      if (!eventId && event)
        setEvent(undefined);
      else if (eventId && +(eventId) !== event?.id)
        fetchContactCustomEvent(organization_id, eventId)
          .then(event => setEvent(event));
    }, [eventId, event, setEvent, ]);

    useEffect(() => {
      if (editConversationVisible) {
        setEditContactVisible(false);
        setCustomerJourneyVisible(false);
        setSecondaryConversationId(undefined);
        setEventId(undefined);
      }
    }, [editConversationVisible, ]);

    useEffect(() => {
      if (editContactVisible) {
        setEditConversationVisible(false);
        setCustomerJourneyVisible(false);
        setSecondaryConversationId(undefined);
        setEventId(undefined);
      }
    }, [editContactVisible, ]);

    useEffect(() => {
      if (customerJourneyVisible) {
        setEditConversationVisible(false);
        setEditContactVisible(false);
        setSecondaryConversationId(undefined);
        setEventId(undefined);
      }
    }, [customerJourneyVisible, ]);

    useEffect(() => {
      if (secondaryConversationId || eventId) {
        setEditConversationVisible(false);
        setEditContactVisible(false);
        setCustomerJourneyVisible(false);
      }
    }, [secondaryConversationId, eventId, ]);

    useEffect(() => {
      if (conversation && journeyLoaded === null)
        setJourneyLoaded(!!conversation?.contact?.id);
    }, [conversation?.contact, journeyLoaded, ]);

    useEffect(() => {
      if (conversation?.contact?.id && journeyLoaded === false) {
        setJourneyLoaded(true);
        fetchConversationJourney(conversation.organization_id, conversation.id).then(journey => {
          dispatch(actionSetConversationJourney({
            conversation_id: conversation.id,
            journey,
          }));
        });
      }
    }, [conversation?.contact?.id, journeyLoaded, ]);

    useEffect(() => {
      if (!isSourceTab) {
        dispatch(actionSetHideStats(editConversationVisible || editContactVisible || customerJourneyVisible || !!secondaryConversationId || !!eventId));
        dispatch(actionSetHideDashboardLeft(!!secondaryConversationId || !!eventId));
      }
    }, [isSourceTab, editConversationVisible, editContactVisible, customerJourneyVisible, secondaryConversationId, setEventId, dispatch, ]);

    useEffect(() => {

      const id = conversation?.id;

      if (!id || source !== ConversationWatcherSourceEnum.Dashboard)
        return;

      dispatch(currentDashboardConversation(id));
      //dispatch(conversationsRemoveFullState());

      setSecondaryConversationId(secondary_conversation_id ? +secondary_conversation_id : undefined);
      setEventId(contact_custom_event_id ? +contact_custom_event_id : undefined);
      setEditContactVisible(false);
      setEditConversationVisible(false);
      setCustomerJourneyVisible(false);

      // return () => {
      //   console.log('unmounted', conversation?.id);
      // };

    }, [conversation?.id, dispatch, secondary_conversation_id, contact_custom_event_id, ]);

    useEffect(() => {
      if (!conversation?.queue_id)
        return;

      setQueue(queues.queues.find(queue => queue.id === conversation.queue_id));
    }, [conversation?.queue_id, queues.queues, ]);

    useEffect(() => {
      if (queue?.conversation_form_id === conversationForm?.id || !forms.loaded)
        return;

      setConversationForm(queue?.conversation_form_id ? forms.dict[queue.conversation_form_id] : undefined);
    }, [queue?.conversation_form_id, forms, ]);

    useEffect(() => {

      if (queue?.create_list_id)
        setList(lists.lists.find(list => list.id === queue.create_list_id));

    }, [queue, lists, ]);

    useEffect(() => {

      if (list?.form_id && !contactForm)
        setContactForm(forms.dict[list.form_id]);
      else if (!list?.form_id && contactForm)
        setContactForm(undefined);
      // else if (!list?.form_id)
      //   setContactForm(default_contact_form(organization_id));

    }, [list, forms?.forms?.length, contactForm, forms.dict, ]);

    useEffect(() => {
      if (contactForm && conversation?.contact) {
        const _initialValues = {};

        Object.keys(contactForm.__validation_on_save).forEach(key => {
          let data_key = key;

          if (key.startsWith('__contact_'))
            data_key = key.substr(10, key.length - 10);

          _initialValues[key] = key !== data_key ? conversation.contact[data_key] : (conversation.contact.data || {})[data_key];
        });
        setContactInitialValues(_initialValues);
      }
    }, [contactForm, conversation?.contact, ]);

    useEffect(() => {

      if (!conversation || !conversationForm)
        return;

      const data = {};

      Object.keys(conversationForm.__validation_on_save).forEach(key => {
        data[key] = conversation.data[key];
      });

      setConversationInitialValues(data);
    }, [conversation, conversationForm, ]);

    useEffect(() => {
      setConversationFormView(conversation && editConversationVisible && conversationForm && conversationInitialValues && <ConversationForm
        initialValues={conversationInitialValues}
        conversation={conversation}
        form={conversationForm}
        editConversationVisible={editConversationVisible}
        setEditConversationVisible={setEditConversationVisible}
      />);
    }, [conversation, editConversationVisible, conversationForm, conversationInitialValues, ]);

    useEffect(() => {
      if (conversation && conversation.user_id === auth.user.id && conversation.unread !== conversation.read)
        readConversation(conversation.organization_id, conversation.id);
    }, [conversation?.id, ]);

    // useEffect(() => {
    //   console.log('global.focused_conversation', focused_conversation, conversation?.id);
    //   if (conversation && focused_conversation === conversation.id && conversation.user_id === auth.user.id && conversation.read < conversation.unread)
    //     readConversation(conversation.organization_id, conversation.id);
    // }, [focused_conversation]);

    if (!conversation)
      return skeleton || <AppSpinner />;

    const patch_conversation = (body: ISharedPatchConversation) => {

      const status = body.status || conversation.status;
      const isClosing = [ConversationStatusEnum.Closed, ConversationStatusEnum.Resolved, ].includes(status);

      return new Promise<ISharedFullConversation>((resolve, reject) => {

        if (conversationForm) {

          const conversation_form_validation = fromJson({
            type: 'object',
            properties: conversationForm[isClosing ? '__validation_on_conversation_close' : '__validation_on_save'],
          }).validate(conversationInitialValues);

          if (conversation_form_validation.error) {
            AlertError(t, {
              content: t(`CONVERSATION_FORM_NOT_VALID_ON_${isClosing ? 'CLOSE' : 'SAVE'}`),
            });
            return reject(t('CONVERSATION_FORM_NOT_VALID'));
          }
        }

        if (contactForm) {

          const contact_form_validation = fromJson({
            type: 'object',
            properties: contactForm[isClosing ? '__validation_on_conversation_close' : '__validation_on_save'],
          }).validate(contactInitialValues);

          if (contact_form_validation.error) {
            AlertError(t, {
              content: t(`CONTACT_FORM_NOT_VALID_ON_${isClosing ? 'CLOSE' : 'SAVE'}`),
            });
            return reject(t('CONTACT_FORM_NOT_VALID'));
          }
        }

        patchConversation(conversation.organization_id, conversation.id, body).then(resolve, error => {
          reject(error);
          AlertError(t, { content: error.toString(), });
        });
      });
    };
    const contact_view = conversation.queue_id && <ConversationContact
      className={'contact-view middle'}
      conversation={conversation}
      leftIcon={!mini}
      bottomIcons={!mini}
      editContactVisible={editContactVisible}
      setEditContactVisible={setEditContactVisible}
      hasContactForm={!!contactForm}
      abilities={abilities}
      contactTimeline={mini ? undefined : {
        get: contactTimelineActive,
        set: setContactTimelineActive,
      }}
    />;
    const contact_form_view = editContactVisible && list && contactForm && contactInitialValues && <ConversationContactForm initialValues={contactInitialValues} list={list} form={contactForm} conversation={conversation} editContactVisible={editContactVisible} setEditContactVisible={setEditContactVisible} />;
    const conversation_title_view = <ConversationTitleBox
      auth={auth}
      patch_conversation={patch_conversation}
      className={!isSourceTab ? 'left' : ''}
      conversation={conversation}
      minimized={isSourceTab}
      conversationTimeline={mini ? undefined : {
        get: conversationTimelineActive,
        set: setConversationTimelineActive,
      }}
    />;
    const customer_journey_view = conversation.journey?.length > 0 && <CustomerJourneyList t={t} conversation={conversation} setSecondaryConversationId={setSecondaryConversationId} setEventId={setEventId} />;

    return <Component
      customerJourneyVisible={customerJourneyVisible}
      setCustomerJourneyVisible={setCustomerJourneyVisible}
      editContactVisible={editContactVisible}
      setEditContactVisible={setEditContactVisible}
      editConversationVisible={editConversationVisible}
      setEditConversationVisible={setEditConversationVisible}
      secondaryConversationId={secondaryConversationId}
      setSecondaryConversationId={setSecondaryConversationId}
      event={event}
      eventId={eventId}
      setEventId={setEventId}
      secondaryConversation={secondaryConversation}
      conversation={conversation}
      conversationForm={conversationForm}
      contact_view={contact_view}
      conversation_title_view={conversation_title_view}
      customer_journey_view={customer_journey_view}
      contact_form_view={contact_form_view}
      conversation_form_view={conversationFormView}
      patch_conversation={patch_conversation}
      conversationTimeline={conversationTimeline}
      onConversationTimelineClose={onConversationTimelineClose}
      contactTimeline={contactTimeline}
      onContactTimelineClose={onContactTimelineClose}
      {...props}
    />;
  };
}
